<template>
  <v-container id="van" fluid tag="section">
    <v-snackbar v-model="editFlash" :timeout="4000" top>
      <span>Van Edit Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="editFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="addFlash" :timeout="4000" top>
      <span>Van Add Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="addFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="deleteFlash" :timeout="4000" top>
      <span>Van Delete Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="deleteFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="isFlashMessage" :timeout="4000" top>
      <span>{{ flashMessage }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="isFlashMessage = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-data-table
      :headers="columns"
      :items="vans"
      item-key="_id"
      class="elevation-1"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-text-field
                @keyup="globalSearch"
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
            <v-col>
              <router-link
                v-if="modulePermission.Create"
                :to="{ name: 'Add Van' }"
              >
                <v-btn color="primary float-right" dark class="mt-4"
                  >Add Van</v-btn
                >
              </router-link>
            </v-col>
          </v-row>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>Confirm Delete</v-card-title
              ><v-card-text class="text-center"
                >Are you sure to delete record ?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" depressed @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary darken-1"
                  depressed
                  @click="deleteItemConfirm"
                  >Delete</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.driver_name`]="{ item }">
        {{
          item.driver_name[0]
            ? item.driver_name[0].first_name +
              " " +
              item.driver_name[0].last_name
            : ""
        }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Van Product',
            params: { id: item._id },
          }"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-2" v-bind="attrs" v-on="on"
                >mdi-rv-truck</v-icon
              >
            </template>
            <span>View Product of Van</span>
          </v-tooltip>

          <!-- <v-btn small class="mr-2">View Product of Van</v-btn> -->
        </router-link>
        <router-link
          v-if="modulePermission.Update"
          :to="{
            name: 'Edit Van',
            params: { id: item._id },
          }"
          ><v-icon class="mr-2">mdi-pencil</v-icon></router-link
        >
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Van',
            params: { id: item._id, action: 'view' },
          }"
          ><v-icon class="mr-2">mdi-eye</v-icon></router-link
        >
        <v-icon v-if="modulePermission.Delete" @click="deleteVan(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <div class="custom_pagination_design">
      <span>
        Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
        <b>{{ totalRecord }}</b> Records
      </span>
      <v-pagination
        class="float-right"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ["edit", "add"],
  data() {
    return {
      moduleName: "Vans",
      modulePermission: [],
      search: "",
      vans: [],
      dialogDelete: false,
      deleteRecord: "",
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      loading: true,
      numbers: [],
      editFlash: false,
      addFlash: false,
      deleteFlash: false,
      isFlashMessage: false,
      flashMessage: "",
    };
  },
  computed: {
    columns() {
      return [
        {
          text: "Actions",
          value: "actions",
          fixed: true,
          sortable: false,
          width: "150px",
        },
        // { sortable: false , text: "Person Name", value: "person_name", width: "150px" },
        // { sortable: false , text: "Zip Code", value: "zipcode" },
        { sortable: false, text: "Vehicle Number", value: "vehicle_number" },
        { sortable: false, text: "Vehicle Type", value: "vehicle_type" },
        { sortable: false, text: "Vehicle Model", value: "vehicle_model" },
        { sortable: false, text: "Driver", value: "driver_name" },
        {
          text: "Vehicle Mfg. Year",
          value: "vehicle_manufacturing_year",
        },
        // { sortable: false , text: "Timing", value: "timing", width: "150px" },
      ];
    },
  },
  methods: {
    getVans() {
      if (this.search == "") {
        this.search = null;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "van/getData/" +
            this.search +
            "?page=" +
            this.page
        )
        .then((response) => {
          if (response.status == 200) {
            this.vans = response.data.vans.data;
            this.loading = false;
            this.totalPages = response.data.vans.last_page;
            this.startRecord = response.data.vans.from;
            this.endRecord = response.data.vans.to;
            this.totalRecord = response.data.vans.total;
            this.numbers = [];
            for (
              let num = response.data.vans.from;
              num <= response.data.vans.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getVans();
    },
    deleteVan(data) {
      if (data.driver_name != null && data.driver_name.length > 0) {
        this.flashMessage = "You can not delete van assigned to driver!";
        this.isFlashMessage = true;
      } else {
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL +
              "van/product/" +
              data._id +
              "?page=1"
          )
          .then((response) => {
            if (response.status == 200) {
              if (
                response.data.vanStock.data != null &&
                response.data.vanStock.data.length > 0
              ) {
                this.flashMessage =
                  "You can not delete product having inventory!";
                this.isFlashMessage = true;
              } else {
                this.dialogDelete = true;
                this.deleteRecord = data._id;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItemConfirm() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + "van/delete/" + this.deleteRecord
        )
        .then((response) => {
          if (response.status == 200) {
            this.dialogDelete = false;
            this.deleteRecord = "";
            this.deleteFlash = true;
            this.getVans();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getVans();
    },
  },
  mounted() {
    this.getVans();
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
    if (this.edit) {
      this.editFlash = true;
    }
    if (this.add) {
      this.addFlash = true;
    }
  },
};
</script>
